import React from "react"
import 'react-hoverable-card/dist/index.css'
import { graphql } from "gatsby"

import SEO from "../components/shared/seo"
import SectionTitle from "../components/shared/SectionTitle"
import Section from "../components/shared/Section"
import Layout from "../components/shared/Layout2"
import NavbarNew from "../components/shared/NavbarNew"
import SectionBreak from "../components/shared/SectionBreak"
import LightText from "../components/shared/LightText"
import Container from "../components/shared/Container"
import BigIcon from "../components/shared/BigIcon"
import MainButton from "../components/shared/MainButton"
import { LangugaeContext } from "../context/LanguageProvider";

class CaseTemplate extends React.Component {

  static contextType = LangugaeContext;

  render() {

    const svData = this.props.data.svAutomation.edges
    const enData = this.props.data.enAutomation.edges
    
    let post = null;

    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      post = this.context.getCorrectPost(svData, enData, window)
    }

    return (post && <Layout>
            <NavbarNew darkMode={true} />
            <SEO title="Computer Vision" />
            <Section>
                <SectionBreak size={80} />

                <Container>
                    <BigIcon width={post?.imageSize?.toString() + "px"} iconUrl={post?.image?.childImageSharp.fluid.src} />
                    <SectionTitle text={post.title} />
                    
                    {post?.textBlocks?.map(block => {
                        return <LightText boldText={block.boldText != '-' ? block.boldText + " " : null} text={block.text} />
                    })}
                </Container>

            {
            post.cta.buttonExist
            ? 
            <MainButton to={post.cta.buttonPath} text={post.cta.buttonText} /> 
            : 
            null
            }
            </Section>
            

        </Layout >)
    }
}

export default CaseTemplate

export const pageQuery = graphql
`query {
  svAutomation: allMdx(
    filter: {
      frontmatter: {templateKey: {eq: "sv-automation"}},
  }, 
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageSize
          title
          textBlocks {
              text
              boldText
          }
          cta {
            buttonExist
            buttonText
            buttonPath
          }
        }
      }
    }
  },


  enAutomation: allMdx(
    filter: {
      frontmatter: {templateKey: {eq: "en-automation"}},
  }, 
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageSize
          title
          textBlocks {
              text
              boldText
          }
          cta {
            buttonExist
            buttonText
            buttonPath
          }
        }
      }
    }
  },
}`

